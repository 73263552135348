.row-flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.flex-sel {
    display: flex;
    margin-bottom: 15px;
}

.infos {
    margin-bottom: 0.5rem;
    display: flex;
}

.hashtag-flexer {
    display: flex;
    align-items: center;
}

.hashtag-flexer>a:hover {
    text-decoration: none;
}

.hashtag {
    height: 20px;
    font-size: 10px;
    border-radius: 10px;
    background: #e0ffff;
    color: #f0972b;
    margin-right: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.img-container {
    margin-right: 100px;
}

.main-photo {
    width: 200px;
}

.selector {
    padding: 13px 20px;
    background-color: #fff0e3;
    border-bottom: 3px solid #777777;
    margin-right: 10px;
    font-size: 1rem;
}

.selector:last-child {
    margin-right: 0;
}

.biography {
    margin-right: 20%;
}

.bio-child {
    margin-bottom: 10px;
}

.bio-child:last-child {
    margin-bottom: 0;
}

.publication, .nstc {
    margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
    .info-container {
        margin-right: 5px;
    }

    .info-container>.infos {
        flex-direction: column;
    }

    .hashtag-flexer {
        flex-direction: column;
        align-items: flex-start;
    }

    .img-container {
        margin-right: 0px;
    }

    .main-photo {
        width: 42vw;
    }

    .flex-sel {
        flex-wrap: wrap;
    }

    .selector {
        flex: 0 0 27%;
        margin-bottom: 5px;
        padding: 12px 10px;
        text-align: center;
    }
}