.cont {
    padding: 50px 100px;
}

/* Navbar */
#navbar {
    background-color: #DABEA7 !important;
}

.nav-link .active {
    color: #000;
}

/* RWD 手機畫面
max-width: 540px 代表螢幕寬度 540px 以下時適用此區域的 CSS值 */
@media screen and (max-width: 540px) {
    * {
        font-size: 12px;
    }

    .cont {
        padding: 25px 30px;
    }
}

@media screen and (min-width: 540px) {
    .navbar {
        height: 70px !important;
    }
}