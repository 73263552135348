.statement {
    font-weight: bold;
    margin-bottom: 20px;
}

form {
    max-width: 600px;
}

@media screen and (min-width: 540px) {
    .cont {
        min-height: calc(100vh - 70px) !important;
    }
}
