.flex-withimg {
    display: flex;
    flex-wrap: wrap; /*暫定*/
}

.info-incumb {
    text-align: center;
    margin-right: 10px;
}

.student-icon {
    width: 200px;
    height: 200px;
    object-fit: cover;
}

.flex-grad {
    display: flex;
    width: 400px;
    flex-wrap: wrap;
}

.infos-grad {
    margin-right: 15px;
}

@media screen and (max-width: 768px) {
    .flex-incumb {
        flex-wrap: wrap;
        width: 80%;
    }

    .info-incumb {
        margin-right: 5px;
    }

    .student-icon {
        width: 30vw;
        height: 30vw;
        object-fit: cover;
    }

    .flex-grad {
        width: 100%;
    }

    .infos-grad {
        flex-basis: 22%;
    }

    ul {
        padding-left: 25px;
    }
}